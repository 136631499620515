import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/article.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Lipoarabinomannan (LAM) is a component of the cell wall shed by `}<em parentName="p">{`Mycobacterium tuberculosis`}</em>{`. Tests based on the detection of LAM in urine have emerged as potential point-of-care tests for TB. Urine-based testing would have advantages over sputum-based testing because urine is easy to collect and store, and lacks the infection control risks associated with sputum collection.`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup></p>
    <p>{`Earlier the test methods were able to detect LAM only in the urine of patients who were HIV positive and had active tuberculosis. But, researchers have been able to develop `}<a parentName="p" {...{
        "href": "https://stm.sciencemag.org/content/9/420/eaal2807"
      }}>{`newer method that can detect LAM in urine of patients who are negative for HIV test`}</a>{` as well.`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup></p>
    <p>{`This new test will hopefully be available in India soon.`}</p>

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}>{`The use of lateral flow urine lipoarabinomannan assay (LF-LAM) for the diagnosis and screening of active tuberculosis in people living with HIV; Policy Update by WHO; Accessible at `}<a parentName="li" {...{
            "href": "https://www.who.int/tb/publications/use-of-lf-lam-tb-hiv/en/"
          }}>{`https://www.who.int/tb/publications/use-of-lf-lam-tb-hiv/en/`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`Urine lipoarabinomannan glycan in HIV-negative patients with pulmonary tuberculosis correlates with disease severity; `}<a parentName="li" {...{
            "href": "https://stm.sciencemag.org/content/9/420/eaal2807"
          }}>{`https://stm.sciencemag.org/content/9/420/eaal2807`}</a><a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      